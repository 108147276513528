import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Section, { Container, Row, Col } from "../components/UI/wrapper"
import { ListadoWrapper } from "../containers/productos/listado/style"
import SEO from "../components/seo"
import Sidebar from "../containers/productos/sidebar"
import BoxLargeImage from "../components/box-large-image/layout-two"
import Heading from "../components/Heading"

const ProductosPorCategoria = ({
  data,
  pageContext,
  location,
  linkStyle,
  boxStyles,
  ...restProps
}) => {
  const { sectionStyle } = restProps
  const products = data.allPrismicProducto.edges
  const catTitle =
    data.allPrismicProducto.edges[0].node.data.categoria.document.data.nombre
      .text

  return (
    <>
      <SEO title="Productos Seggasa" />
      <main className="site-wrapper-reveal">
        <Section {...sectionStyle}>
          <Row>
            <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
              <Sidebar />
            </Col>
            <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
              <Heading as="h3" content={catTitle} />
              <ListadoWrapper>
                <Container>
                  <Row>
                    {products.map((item, index) => (
                      <Col
                        lg={4}
                        md={6}
                        className="box-item"
                        key={`box-image-${index}`}
                      >
                        <BoxLargeImage
                          {...boxStyles}
                          imageSrc={item.node.data.foto.fixed.src}
                          btnText={item.node.data.titulo.text}
                          desc={item.node.data.marca.document.data.marca.text.replace(
                            /^\w/,
                            c => c.toUpperCase()
                          )}
                          path={`/productos/${item.node.uid}`}
                        />
                      </Col>
                    ))}
                  </Row>
                </Container>
              </ListadoWrapper>
            </Col>
          </Row>
        </Section>
      </main>
    </>
  )
}

export const query = graphql`
  query ProductsByCategoryQuery($categoria: String) {
    allPrismicProducto(
      filter: { data: { categoria: { uid: { eq: $categoria } } } }
    ) {
      edges {
        node {
          id
          data {
            titulo {
              text
            }
            foto {
              fixed(width: 200, height: 200) {
                ...GatsbyPrismicImageFixed
              }
            }
            marca {
              document {
                ... on PrismicMarca {
                  data {
                    marca {
                      text
                    }
                  }
                }
              }
            }
            categoria {
              document {
                ... on PrismicCategoria {
                  id
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`

ProductosPorCategoria.propTypes = {
  sectionStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  boxStyles: PropTypes.object,
}

ProductosPorCategoria.defaultProps = {
  sectionStyle: {
    pt: "89px",
    pb: "100px",
    responsive: {
      medium: {
        pt: "72px",
        pb: "80px",
      },
      small: {
        pt: "53px",
        pb: "60px",
      },
    },
  },
  boxStyles: {
    headingStyle: {
      color: "#000",
    },
  },
  linkStyle: {
    layout: "underline",
    fontSize: "18px",
    fontWeight: 500,
    lineheight: 1.4,
    color: "primary",
    hover: {
      layout: 2,
    },
  },
}
export default ProductosPorCategoria
